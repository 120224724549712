import React from 'react'
import { BannerContainer, TextFrame } from './styled'

export const SHOW_BANNER = false

export default () => (
  <BannerContainer>
    <TextFrame>The PGA of America Office will be closed as of Friday, December 23, 2022 through Monday, January 2, 2023</TextFrame>
  </BannerContainer>
)
