import styled from 'styled-components'
import { Theme } from '@pga/pga-component-library'

const { Montserrat } = Theme.fonts

export const BannerContainer = styled.div`
  width: 100%;
  background: ${Theme.colors.pearlGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 767.98px) {
    flex-direction: column;
    padding-bottom: 12px;
  }
`

export const TextFrame = styled.p`
  text-align: center;
  font-family: ${Montserrat};
  font-size: 16px;
  color: ${Theme.colors.primary};
  margin-bottom: 0;
  padding: 10px;
  @media (max-width: 768px) {
    width: 500px;
    max-width: 100%;
  }
`

export const TitleLink = styled.a`
  font-size: 16px;
  font-weight: 400;
  font-family: ${Montserrat};
  color: ${Theme.colors.primary};
  text-align: center;
  display: inline;
  text-decoration: underline;

  @media screen and (max-width: 767.98px) {
    line-height: 1.5;
    margin: 0;
  }
`
