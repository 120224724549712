import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import MediaQuery from 'react-responsive'
import { Header, Nav, IconsBox, MobileNavigation, Theme, MyAccountButton, IframeRolex } from '@pga/pga-component-library'
import { parseEdges } from '../../utils'
import logo from '../../images/pga-logo.svg'

const NavigationBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

const SideNavLink = styled(Link)`
  font-family: ${Theme.fonts.Montserrat};
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  position: relative;

  &.active::before {
    content: '';
    position: absolute;
    vertical-align: middle;
    width: 100%;
    color: ${Theme.colors.gold};
    bottom: -10px;
    border-bottom: 4px solid;
  }

  :hover, &.active {
    text-decoration: none;
    color: #fff;
  }
`

const LinkStyled = styled(Link)`
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;

  width: 100%;
  flex-grow: 45;
  position: relative;

  &.active::before {
    content: '';
    position: absolute;
    vertical-align: middle;
    width: 100%;
    color: ${Theme.colors.gold};
    bottom: -31px;
    border-bottom: 4px solid;
  }

  :hover,
  &.active {
    text-decoration: none;
    color: #fff;
    font-weight: 700;
  }
`

export default () => {
  const logoLink = '/'
  const { allNavigationYaml, allNavigationMobileYaml } = useStaticQuery(allNavigationData)
  const options = parseEdges(allNavigationYaml.edges)
  const navMobileOptions = parseEdges(allNavigationMobileYaml.edges)
  const mobileOptions = [...options, ...navMobileOptions]

  return (
    <Header {...{ logo, bgColor: Theme.colors.primary, logoLink }}>
      <MediaQuery minWidth={992}>
        <NavigationBox>
          <Nav options={options} comp={LinkStyled} />
          <div className='d-flex align-items-center'>
            <IframeRolex />
            <IconsBox />
          </div>
          <MyAccountButton />
        </NavigationBox>
      </MediaQuery>
      <MediaQuery maxWidth={991}>
        <MobileNavigation {...{ linkComponent: SideNavLink, options: mobileOptions, comp: SideNavLink, b: -7, l: 11 }} vertical />
      </MediaQuery>
    </Header>
  )
}

export const allNavigationData = graphql`
  query {
    allNavigationYaml {
      edges {
        node {
          id
          label
          to
          root
          external
        }
      }
    }
    allNavigationMobileYaml {
      edges {
        node {
          id
          to
          label
          external
          target
        }
      }
    }
  }
`
