import { createGlobalStyle } from 'styled-components'
import { Theme } from '@pga/pga-component-library'

const GlobalStyle = createGlobalStyle`
  h1, h2, h3, h4, h5 {
    font-family: ${Theme.fonts.PlayfairDisplay};
    color: ${Theme.colors.primary};
  }
  p {
    color: ${Theme.colors.primary};
  }
  .title-serif {
    a {
      font-family: ${Theme.fonts.PlayfairDisplay};
    }
  }
  li p {
    font-size: 15px;
    line-height: 20px;
  }
  .img-gradient-bottom {
    position: relative;
    &:before {
      content: '';
      position:absolute;
      top:0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(0deg, #00234B 0%, #00234B 12%, rgba(0, 35, 75, 0.7) 20%, rgba(0, 35, 75, 0) 45%, rgba(0, 35, 75, 0) 100%);
      z-index: 1;
    }
  }
`

export default GlobalStyle
