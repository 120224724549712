import { useEffect } from 'react';

const OneTrust = () => {
    useEffect(() => {
        const oneTrustKey = process.env.GATSBY_ONETRUST_COOKIE_KEY;

        const scriptElement = document.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.charset = 'UTF-8';
        scriptElement.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
        scriptElement.setAttribute('data-domain-script', oneTrustKey);
        document.head.appendChild(scriptElement);

        window.OptanonWrapper = function() { };

        return () => {
            document.head.removeChild(scriptElement);
        };
    }, []);

    return null;
};

export default OneTrust;
