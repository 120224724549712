import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Header from './Header'
import SubNavigation from './SubNavigation'
import Seo from './seo'
import Footer from './Footer'
import GlobalStyles from '../styles/global'
import HeaderBanner, { SHOW_BANNER } from './HeaderBanner'
import OneTrustCookies from "./OneTrustCookies";

export const BodyWrapper = styled.div`
  background-color: ${props => props.bgColor || 'white'};
`

const Layout = ({ children, frontmatter = {}, bgColor, location = {}, subNavFixed, hideSubNav = false }) => (
  <BodyWrapper bgColor={bgColor || frontmatter.body_color}>
    <Seo {...frontmatter} />
    {SHOW_BANNER && <HeaderBanner />}
    <Header />
    {!hideSubNav && <SubNavigation {...{ location, subNavFixed }} />}
    <div>
      <main>{children}</main>
      <Footer />
    </div>
    <OneTrustCookies />
    <GlobalStyles />
  </BodyWrapper>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
