import { path, values } from 'ramda'

export const replaceDash = (string = '') => string.replace(/-/g, ' ')

export const parseSlug = (slug = '') => {
  return slug
    .split('/')
    .filter(s => s)
}

export const parseFrontMatterFromQuery = path(
  ['data', 'allMarkdownRemark', 'edges', '0', 'node', 'frontmatter']
)

export const parseHtmlFromQuery = path(['data', 'allMarkdownRemark', 'edges', '0', 'node', 'html'])

export const parseEdges = (edges) => edges.map(edge => values(edge)[0])
